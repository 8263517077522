import * as React from "react"
import { Link } from "gatsby"
import { FeaturedProjectsStyles } from "./FeaturesStyles"
import FeaturedProject from "./FeaturedProject"
import useFeaturedProject from "../../hooks/use-featured-project"
import Button from "../Button/Button"

const Features = ({ title, introduction }) => {
  const featuredProjects = useFeaturedProject()

  return (
    <FeaturedProjectsStyles className="section">
      {title || introduction ? (
        <div className="container container__tight">
          <div className="intro__area">
            {title && <h2>{title}</h2>}
            {introduction && <p>{introduction}</p>}
          </div>
        </div>
      ) : null}

      <div className="container container__tight container__scroll">
        {featuredProjects.map((node, index) => {
          return <FeaturedProject feature={node} key={index} />
        })}
      </div>
      <div className="container container__tight learn__more">
        <Button as={Link} to="/projects" text="All Projects" />
      </div>
    </FeaturedProjectsStyles>
  )
}

export default Features
