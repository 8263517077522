import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModule from "../components/BannerModule/BannerModule"
import BasicTextModule from "../components/BasicTextModule/BasicTextModule"
import PerksModule from "../components/PerksModule/PerksModule"
import Perk from "../components/PerksModule/Perk"
import Features from "../components/Features/Features"

const Index = () => {
  return (
    <>
      <Seo title="Home" />
      <Layout>
        <BannerModule
          title="Stefano Metafuni"
          subTitle="Frontend web developer."
          logo
          enquire
        />
        <BasicTextModule
          title="I am a frontend web developer based in Dundee, Scotland, and a Jamstack enthusiast."
          content="I have previously worked internationally in sales, technical support and e-commerce for multinational businesses. My drive is to develop memorable experiences through digital applications. My goal is to successfully work with design and development teams in order to bring concepts to life and achieve the finest results."
          link="/about"
          linkText="View more"
        />
        <PerksModule>
          <Perk title="The Title" content="The content" />
        </PerksModule>
        <Features
          title="Projects."
        />
      </Layout>
    </>
  )
}

export default Index
